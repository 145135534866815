export function getQueryStringArgs(text) {
  var qs = location.search.length > 0 ? location.search.substring(1) : "";
  qs = location.href.indexOf("?") >= 0 ? location.href.split("?")[1] : qs;
  if (text) {
    qs = text.indexOf("?") >= 0 ? text.split("?")[1] : qs;
  }
  var args = {},
    items = qs.length ? qs.split("&") : [],
    item = null,
    name = null,
    value = null,
    i = 0,
    len = items.length;
  for (i = 0; i < len; i++) {
    item = items[i].split("=");
    name = decodeURIComponent(item[0]);
    value = decodeURIComponent(item[1]);
    if (name.length) {
      args[name] = value;
    }
  }
  return args;
}

//Array.prototype.indexOf = function(val, key) {
//   for (var i = 0; i < this.length; i++) {
//     if (this[i][key] == val) return i;
//   }
//   return -1;
// };
export function arrayIndexOf(arr, val, key) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][key] == val) return i;
  }
  return -1;
}

// Array.prototype.remove = function(val, key) {
//   var index = this.indexOf(val, key);
//   if (index > -1) {
//     return this.splice(index, 1);
//   }
//   return "";
// };
export function arrayRemove(arr, val, key) {
  var index = arrayIndexOf(arr, val, key);
  if (index > -1) {
    return arr.splice(index, 1);
  }
  return "";
}

export function detectPhone() {
  let u = navigator.userAgent;
  if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
    //安卓手机
    return "android";
  } else if (u.indexOf("iPhone") > -1) {
    //苹果手机
    return "iphone";
  } else if (u.indexOf("Windows Phone") > -1) {
    //winphone手机
    return "winphone";
  } else {
    return "unknow";
  }
}

export function checkPhone(phone) {
  if (!/^1\d{10}$/.test(phone)) {
    return false;
  }
  return true;
}

//获得字符串实际长度，中文2，英文1
export function getLength(str) {
  var realLength = 0,
    len = str.length,
    charCode = -1;
  for (var i = 0; i < len; i++) {
    charCode = str.charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) realLength += 1;
    else realLength += 2;
  }
  return realLength;
}

export function temporaryRepairWxKeyboardHide() {
  var ua = navigator.userAgent.toLowerCase();
  var isWeixin = ua.match(/MicroMessenger/i) == "micromessenger";
  if (!isWeixin) {
    return;
  }
  var currentPosition, timer;
  var speed = 1; //页面滚动距离
  timer = setInterval(function() {
    currentPosition =
      document.documentElement.scrollTop || document.body.scrollTop;
    currentPosition -= speed;
    window.scrollTo(0, currentPosition); //页面向上滚动
    currentPosition += speed; //speed变量
    window.scrollTo(0, currentPosition); //页面向下滚动
    clearInterval(timer);
  }, 1);
}

export let localStorageExpiration = {
  setItem: function(key, jsonData, expirationSecond) {
    localStorage.setItem(
      key,
      JSON.stringify({
        value: JSON.stringify(jsonData),
        timestamp: new Date().getTime() + expirationSecond * 1000
      })
    );
    return jsonData;
  },
  getItem: function(key) {
    var record = JSON.parse(localStorage.getItem(key));
    if (!record) {
      return false;
    }
    return new Date().getTime() < record.timestamp && JSON.parse(record.value);
  }
};
